import React from "react";
import { Link } from "gatsby";

import styles from "./style.module.scss";

export const Footer = () => (
  <div className={styles.footer}>
    <Link to="/" className={styles.footerText}>
      HOME
    </Link>
    <Link to="/profile/" className={styles.footerText}>
      PROFILE
    </Link>
    <Link to="/privacypolicy/" className={styles.footerText}>
      PRIVACY POLICY
    </Link>
    <Link to="/publications/" className={styles.footerText}>
      PUBLICATIONS
    </Link>
  </div>
);
