import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";

import styles from "./style.module.scss";

type OuterProps = {
  isHome?: boolean | false;
};

export const Header: React.FC<OuterProps> = (props: OuterProps) => {
  const { isHome } = props;

  return (
    <StaticQuery
      query={graphql`
        query {
          site: site {
            siteMetadata {
              title
              keywords
              description
              siteUrl
              twitterUsername
              google_site_verification
            }
          }
        }
      `}
      render={(data) => (
        <div className={styles.header}>
          <div className={styles.HeaderInner}>
            <Link to="/">
              {isHome === true ? (
                <h1 className={styles.siteTitle}>
                  {data.site.siteMetadata.title}
                </h1>
              ) : (
                <div className={styles.siteTitle}>
                  {data.site.siteMetadata.title}
                </div >
              )}
            </Link>
          </div>
        </div>
      )}
    />
  );
};
